import "./App.css";
import logoSilva from "./assets/logoSilva.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img className="logo-silva" src={logoSilva} alt="Logo" />
        <div className="c-loader"></div>
        {/* <div className="progress">
          <div className="progress-bar"></div>
        </div> */}
        <p>
          Estou sendo construído, <b> vem novidade em breve. </b>
        </p>
        <a
          className="App-link"
          href="https://wa.me/+5534998185766"
          target="_blank"
          rel="noopener noreferrer"
        >
          Atendimento via Whatsapp
        </a>
      </header>
    </div>
  );
}

export default App;
